import { addReducer } from "./uselocalstorage";

export const DEFAULT_STATE = {
	jwt: null,
	user: {},
	loggedIn: false,
	registered: false
};

export const reducer = (state, action) => {
	switch (action.type) {
		case "LOGIN":
			const { jwt = null, user = {} } = action.payload;
			return { ...state, jwt, user, registered: true, loggedIn: true };
		case "LOGOUT":
			return {
				...state,
				jwt: null,
				user: {},
				registered: false,
				loggedIn: false
			};
		case "REGISTER":
			return { ...state, registered: true };
		case "CONFIRMEMAIL":
			return { ...state, emailConfirmed: true };
		default:
			return state;
	}
};

addReducer("auth", reducer, DEFAULT_STATE);
