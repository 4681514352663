import React from "react"
import { useEmailForm } from "../util/useEmailForm"
import { isEmptyObject } from "../util/object"
import { ReactHookFormTextArea } from "./shared/ReactHookFormTextArea"
const consultationFormURL = process.env.CONSULTATION_FORM_URL

const formDefaultValues = {
  subject: "chinesetutor.org - request for consultation",
  name: "",
  email: "",
  age_group: "DEFAULT",
  student_level: "DEFAULT",
  lesson_type: "DEFAULT",
  time_zone: "DEFAULT",
  comment: "",
}

const formRegisterOptions = {
  subject: {},
  name: { required: "This field is required." },
  email: {
    required: "This field is required.",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Please provide a valid email.",
    },
  },
  age_group: {
    validate: value => {
      if (value === "DEFAULT") {
        return "This field is required."
      }
    },
    defaultValue: "DEFAULT",
  },
  student_level: {
    defaultValue: "DEFAULT",
  },
  lesson_type: {
    defaultValue: "DEFAULT",
  },
  time_zone: {
    defaultValue: "DEFAULT",
    validate: value => {
      if (value === "DEFAULT") {
        return "This field is required."
      }
    },
  },
  comment: {
    validate: value => {
      if (value === "") {
        return "This field is required."
      }
    },
  },
}

const MainHeader = () => {
  const {
    submitHandler,
    register,
    submitting,
    formErrors,
    submitError,
    submitStatus,
    control,
  } = useEmailForm({
    apiUrl: consultationFormURL,
    redirectUrl: "/",
    defaultValues: formDefaultValues,
  })
  return (
    <div className="hero row align-items-center">
      <div className="col-md-7">
        <h2>
          Learn <span>Chinese</span>
        </h2>
        <h2>online</h2>
        <p>
          Choose from our wide assortment of personally vetted, experienced and
          certified tutors.
        </p>
        <a className="btn" href="#!">
          Explore Now
        </a>
      </div>
      <div id="consultation-form" className="col-md-5">
        <div className="form">
          <h3>Request a free consultation</h3>
          <form onSubmit={submitHandler}>
            <input
              {...register("subject", formRegisterOptions.subject)}
              type="hidden"
              className="form-control"
            />
            <input
              {...register("name", formRegisterOptions.name)}
              className={`form-control${
                formErrors?.name ? " formFieldError_border" : ""
              }`}
              type="text"
              placeholder="Your Name (*)"
            />
            <input
              {...register("email", formRegisterOptions.email)}
              className={`form-control${
                formErrors?.email ? " formFieldError_border" : ""
              }`}
              type="text"
              placeholder="Your email address (*)"
            />
            <div className={`control-group`}>
              <select
                {...register("age_group", formRegisterOptions.age_group)}
                className={`custom-select${
                  formErrors?.age_group ? " formFieldError_border" : ""
                }`}
              >
                <option value="DEFAULT">Student age group (*)</option>
                <option value="YOUNG_LEARNER">Young learner</option>
                <option value="TEENAGER">Teenager</option>
                <option value="ADULT">Adult</option>
              </select>
            </div>
            <div className="control-group">
              <select
                {...register(
                  "student_level",
                  formRegisterOptions.student_level
                )}
                className={`custom-select${
                  formErrors?.student_level ? " formFieldError_border" : ""
                }`}
              >
                <option value="DEFAULT">Student level</option>
                <option value="BEGINNER">Beginner</option>
                <option value="INTERMEDIATE">Intermediate</option>
                <option value="ADVANCED">Advanced</option>
              </select>
            </div>
            <div className="control-group">
              <select
                {...register("lesson_type", formRegisterOptions.lesson_type)}
                className={`custom-select${
                  formErrors?.lesson_type ? " formFieldError_border" : ""
                }`}
              >
                <option value="DEFAULT">Lesson type</option>
                <option value="GENERAL_CHINESE">
                  General Chinese learning
                </option>
                <option value="BUSINESS_CHINESE">Business Chinese</option>
                <option value="HSK_PREPARATION">HSK preparation</option>
              </select>
            </div>
            <div className="control-group">
              <select
                {...register("time_zone", formRegisterOptions.time_zone)}
                className={`custom-select${
                  formErrors?.time_zone ? " formFieldError_border" : ""
                }`}
              >
                <option value="DEFAULT">Your time zone (*)</option>
                <option value="0">Greenwich Mean Time (GMT)</option>
                <option value="0">Universal Coordinated Time (GMT)</option>
                <option value="1">European Central Time (GMT+1:00)</option>
                <option value="2">Eastern European Time (GMT+2:00)</option>
                <option value="3">
                  (Arabic) Egypt Standard Time (GMT+2:00)
                </option>
                <option value="4">Eastern African Time (GMT+3:00)</option>
                <option value="3.5">Middle East Time (GMT+3:30)</option>
                <option value="4">Near East Time (GMT+4:00)</option>
                <option value="5">Pakistan Lahore Time (GMT+5:00)</option>
                <option value="5.5">India Standard Time (GMT+5:30)</option>
                <option value="6">Bangladesh Standard Time (GMT+6:00)</option>
                <option value="7">Vietnam Standard Time (GMT+7:00)</option>
                <option value="8">China Taiwan Time (GMT+8:00)</option>
                <option value="9">Japan Standard Time (GMT+9:00)</option>
                <option value="9.5">Australia Central Time (GMT+9:30)</option>
                <option value="10">Australia Eastern Time (GMT+10:00)</option>
                <option value="11">Solomon Standard Time (GMT+11:00)</option>
                <option value="12">
                  New Zealand Standard Time (GMT+12:00)
                </option>
                <option value="-11">Midway Islands Time (GMT-11:00)</option>
                <option value="-10">Hawaii Standard Time (GMT-10:00)</option>
                <option value="-9">Alaska Standard Time (GMT-9:00)</option>
                <option value="-8">Pacific Standard Time (GMT-8:00)</option>
                <option value="-7">Phoenix Standard Time (GMT-7:00)</option>
                <option value="-7">Mountain Standard Time (GMT-7:00)</option>
                <option value="-6">Central Standard Time (GMT-6:00)</option>
                <option value="-5">Eastern Standard Time (GMT-5:00)</option>
                <option value="-5">
                  Indiana Eastern Standard Time (GMT-5:00)
                </option>
                <option value="-4">
                  Puerto Rico and US Virgin Islands Time (GMT-4:00)
                </option>
                <option value="-3.5">
                  Canada Newfoundland Time (GMT-3:30)
                </option>
                <option value="-3">Argentina Standard Time (GMT-3:00)</option>
                <option value="-3">Brazil Eastern Time (GMT-3:00)</option>
                <option value="-1">Central African Time (GMT-1:00)</option>
              </select>
            </div>
            <ReactHookFormTextArea
              id="comment"
              label="Comment"
              className="form-control"
              errorFieldClassName="formFieldError_border"
              defaultValue=""
              formErrors={formErrors}
              control={control}
              //   rules={formRegisterOptions.comment}
            />
            <div
              className={
                !(submitStatus && !submitError)
                  ? "formSubmitError"
                  : "formSubmitSuccess"
              }
            >
              {
                // prettier-ignore
                submitError?submitError:(submitStatus?"Your request has been sent successfully!":(
                    isEmptyObject(formErrors)?null:"Please fill in all required fields correctly"
                ))
              }
            </div>
            <div className="btndiv">
              <button disabled={submitting} className="btn btn-block">
                {submitting ? "sending.." : "Send"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default MainHeader
