import { useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { navigate } from "@reach/router"

export const useEmailForm = ({ apiUrl, redirectUrl, defaultValues }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({ defaultValues: defaultValues, reValidateMode: "onChange" })

  const [serverState, setServerState] = useState({
    submitting: false,
    submitStatus: null,
    submitError: null,
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      submitStatus: { ok, msg },
    })
    if (ok) {
      reset()
      navigate(redirectUrl)
    } else setServerState({ submitError: msg })
  }

  const handleOnSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: apiUrl,
      data,
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form)
      })
      .catch(r => {
        if (!r.response?.data?.error) {
          handleServerResponse(
            false,
            "Could not contact server. Please check your internet connection.",
            form
          )
        } else handleServerResponse(false, r.response.data.error, form)
      })
  }

  return {
    submitHandler: handleSubmit(handleOnSubmit),
    register,
    submitting: serverState.submitting,
    formErrors: errors,
    submitError: serverState.submitError,
    control,
    submitStatus: serverState.submitStatus,
  }
}
