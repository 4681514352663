const authPages = ["/login", "/register", "/confirmemail", "/emailconfirmed"]

export const passRedirectThroughAuth = (location, link) => {
  const hrefIsAuthPage = authPages.some(uri => location.href?.endsWith(uri))
  const linkIsAuthPage = authPages.some(uri => link.endsWith(uri))
  if (linkIsAuthPage) {
    //going to an authpage
    if (hrefIsAuthPage) {
      //also coming from an authpage
      //--> just pass the existing redirect, unless it's an auth page (shouldn't happen)
      if (location.state?.redirect) {
        //an auth redirect is available
        //--> check if it's not an auth page
        const redirectIsAuthPage = authPages.some(uri =>
          location.state.redirect.endsWith(uri)
        )
        if (redirectIsAuthPage) {
          //if it is an auth page, pass null
          return null
        } else {
          //else, pass the redirect
          return location.state.redirect
        }
      } else {
        //going from auth to auth page, but no redirect available (shouldn't happen)
        //--> go to main page
        return "/"
      }
    } else {
      //coming from a non-auth page
      //--> create redirect from current url
      return location.href
    }
  } else {
    //going to a non-auth page
    //--> just process existing redirects
    if (hrefIsAuthPage) {
      //coming from an auth page
      //--> drop the redirect
      return null
    } else {
      //coming from a non-auth page (this shouldn't happen)
      //--> return null
      return null
    }
  }
}
