import { useLocalStorageReducer } from "./uselocalstorage"
import axios from "axios"
const apiURL = process.env.GATSBY_API_URL
require("./useStrapiAuth.context")

export const useAuth = () => {
  const [state, dispatch] = useLocalStorageReducer("auth")
  const isAuthenticated = state.loggedIn && Object.keys(state.user).length !== 0

  const login = async credentials => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: payload } = await axios.post(
          `${apiURL}/auth/local`,
          credentials
        )
        dispatch({ type: "LOGIN", payload })
        if (payload?.user?.confirmed) {
          resolve(payload)
        } else {
          reject({
            response: {
              data: {
                message: [
                  {
                    messages: [
                      {
                        message:
                          "Please check your email and confirm your registration first.",
                      },
                    ],
                  },
                ],
              },
            },
          })
        }
      } catch (e) {
        reject(e)
      }
    })
  }

  const logout = () => {
    dispatch({ type: "LOGOUT" })
  }

  const register = async credentials =>
    new Promise(async (resolve, reject) => {
      try {
        const { data: payload } = await axios.post(
          `${apiURL}/auth/local/register`,
          credentials
        )
        dispatch({ type: "REGISTER", payload })
        resolve(payload)
      } catch (e) {
        reject(e)
      }
    })

  const confirmEmail = async code =>
    new Promise(async (resolve, reject) => {
      try {
        const { data: payload } = await axios.get(
          `${apiURL}/auth/email-confirmation`,
          {
            params: { confirmation: code },
          }
        )
        dispatch({ type: "CONFIRMEMAIL", payload })
        resolve(payload)
      } catch (e) {
        reject(e)
      }
    })

  return {
    // ...state,
    isAuthenticated,
    login,
    logout,
    register,
    confirmEmail,
  }
}

export default useAuth
