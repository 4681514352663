import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import Header from "../components/header"
import Footer from "../components/footer"
import "./layout.css"

const Layout = ({ currPage, children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            label
            link
          }
        }
      }
    }
  `)
  const menuLinks = data.site.siteMetadata.menuLinks
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link href="/css/bootstrap-ltd.min.css" rel="stylesheet" />
        {/*<link rel="preload" href="/css/bootstrap.min.css" as="style" onload="this.onload=null;this.rel='stylesheet'" />*/}
        {/*<noscript><link rel="stylesheet" href="/css/bootstrap.min.css" /></noscript>*/}
        <link href="/css/all-ltd.min.css" rel="stylesheet" />
        <link href="/css/steps.min.css" rel="stylesheet" />
        <link href="/css/style.css" rel="stylesheet" />
        {/*<link href="/css/react-responsive-carrousel.css" rel="stylesheet" />*/}
        <link href="/css/alice-carousel.css" rel="stylesheet" />
        <link rel="stylesheet" href="/css/font-awesome-ltd.min.css" />
        {/*<script src="/lib/jquery-3.4.1.min.js" />*/}
        {/*<script src="/lib/bootstrap.bundle.min.js" />*/}
      </Helmet>
      <div className="wrapper">
        <Header currPage={currPage} menuLinks={menuLinks} location={location} />
        {children}
        <Footer />
        {/*<button type="button" className="back-to-top" onClick={backToTop}><i className="fa fa-chevron-up" aria-label="back-to-top"></i></button>*/}
        <a href="!#" className="back-to-top">
          <i className="fa fa-chevron-up" aria-label="back-to-top"></i>
        </a>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  currPage: PropTypes.string.isRequired,
}

export default Layout
