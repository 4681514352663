import * as React from "react"
import { useState, useEffect } from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Navbar = ({ menuLinks, currPage }) => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const [buttonClass, setButtonClass] = useState("navbar-toggler");
    const [colMenuClass, setColMenuClass] = useState("collapse navbar-collapse justify-content-between show");

    useEffect(()=>{
        setNavbarOpen(false);
    },[]);

    useEffect(()=>{
        if (navbarOpen) {setButtonClass("navbar-toggler");
            setColMenuClass("collapse navbar-collapse justify-content-between show");}
        else {setButtonClass("navbar-toggler collapsed");
        setColMenuClass("collapse navbar-collapse justify-content-between")}
    }, [navbarOpen, buttonClass, colMenuClass])

    return (
        <div className="navbar navbar-expand-lg bg-light navbar-light">
            <a href="#!" className="navbar-brand">MENU</a>
            <button type="button" className={buttonClass} data-toggle="collapse"
                    onClick= {() => setNavbarOpen((navbarOpen) => !navbarOpen)}
                    data-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className={colMenuClass} id="navbarCollapse">
                <div className="navbar-nav ml-auto">
                    <ul className="multiLineMenu">
                        {
                            menuLinks.map((link)=> {
                                const className=(link.name===currPage)?"nav-item nav-link active":"nav-item nav-link";
                                return (
                                    <li
                                        key={link.name}
                                        style={{
                                            listStyleType: `none`,
                                        }}
                                    >
                                        <Link to={link.link} className={className}>{link.label}</Link>
                                    </li>

                                )
                            })
                        }
                    </ul>
                    {/*<!--<div className="nav-item dropdown">-->*/}
                    {/*<!--<a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Dropdown</a>-->*/}
                    {/*<!--<div className="dropdown-menu">-->*/}
                    {/*<!--<a href="#" className="dropdown-item">Sub Item 1</a>-->*/}
                    {/*<!--<a href="#" className="dropdown-item">Sub Item 2</a>-->*/}
                    {/*<!--</div>-->*/}
                    {/*<!--</div>-->*/}
                    {/*<!--<a href="#" className="btn">Get A Quote</a>-->*/}
                </div>
            </div>
        </div>
    )
}

export default Navbar