import React from "react"
import { Controller } from "react-hook-form"

const TextArea = props => {
  return (
    <textarea
      id={props.id}
      placeholder={props.placeholder}
      className={props.className}
      value={props.value}
      onChange={value => props.onChange(value)}
    />
  )
}

export const ReactHookFormTextArea = props => {
  const {
    id,
    label,
    className,
    errorFieldClassName,
    defaultValue,
    formErrors,
    control,
    rules,
  } = props
  return (
    <Controller
      name={id}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <TextArea
          id={id}
          className={`${className}${
            formErrors?.[id] ? ` ${errorFieldClassName}` : ""
          }`}
          placeholder={label}
          value={value}
          onChange={value => onChange(value)}
        />
      )}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
    />
  )
}
