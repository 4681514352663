import * as React from "react"
// import PropTypes from "prop-types""
import { Link } from "gatsby"

const Footer = () => {
  const currYear = new Date().getFullYear()
  return (
    <div className="footer">
      {/* <div className="container"> */}
      {/* <div className="row">
          <div className="col-md-6 col-lg-3">
            <div className="footer-contact">
              <h2>Get In Touch</h2>
              <p>
                <i className="fa fa-map-marker-alt" />
                123 Street, New York, USA
              </p>
              <p>
                <i className="fa fa-phone" />
                +012 345 67890
              </p>
              <p>
                <i className="fa fa-envelope" />
                info@example.com
              </p>
              <div className="footer-social">
                <a href="#!">
                  <i className="fa fa-twitter" aria-label="twitter" />
                </a>
                <a href="#!">
                  <i className="fa fa-facebook-f" aria-label="facebook" />
                </a>
                <a href="#!">
                  <i className="fa fa-youtube" aria-label="youtube" />
                </a>
                <a href="#!">
                  <i className="fa fa-instagram" aria-label="instagram" />
                </a>
                <a href="#!">
                  <i className="fa fa-linkedin-in" aria-label="linkedin" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="footer-link">
              <h2>Useful Link</h2>
              <a href="#!">About Us</a>
              <a href="#!">Our Story</a>
              <a href="#!">Our Services</a>
              <a href="#!">Our Projects</a>
              <a href="#!">Contact Us</a>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="footer-link">
              <h2>Useful Link</h2>
              <a href="#!">Our Clients</a>
              <a href="#!">Clients Review</a>
              <a href="#!">Ongoing Project</a>
              <a href="#!">Customer Support</a>
              <a href="#!">FAQs</a>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="footer-form">
              <h2>Stay Updated</h2>
              <p>
                Lorem ipsum dolor sit amet, adipiscing elit. Etiam accumsan
                lacus eget velit
              </p>
              <input className="form-control" placeholder="Email here" />
              <button className="btn">Submit</button>
            </div>
          </div>
        </div> */}
      {/* </div> */}
      <div className="container footer-menu">
        <div className="f-menu">
          <Link key="1" to="/terms-of-use">
            Terms of use
          </Link>
          <Link key="2" to="/privacy-policy">
            Privacy policy
          </Link>
          <Link key="3" to="/contact">
            Contact us
          </Link>
        </div>
      </div>
      <div className="container copyright">
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/*<div className="col-md-6">*/}
          {/*<p>© <a href="https://redhot.consulting">RedHot Consulting</a>, All Right Reserved.</p>*/}
          {/*</div>*/}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <p>
              © {currYear === 2021 ? "2021" : "2021-" + currYear}{" "}
              ChineseTutor.org. Developed By{" "}
              <a href="https://redhotcoding.com">RedHotCoding</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

// Header.propTypes = {
//     siteTitle: PropTypes.string,
// }
//
// Header.defaultProps = {
//     siteTitle: ``,
// }

export default Footer
