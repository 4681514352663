import * as React from "react"
import PropTypes from "prop-types"
import MainHeader from "./mainheader.js"
import ProfileNavBar from "./profilenavbar"
import Navbar from "./navbar"

const Header = ({ siteTitle, menuLinks, currPage, location }) => {
  return (
    <div className={currPage === "home" ? "header home" : "header home-no-bg"}>
      <div className="container-fluid">
        <div className="header-top row align-items-center">
          <div className="col-lg-3">
            <div className="brand">
              <a href="/">
                {/*<StaticImage src="../images/logo.svg" width={300} height={100} alt="logo"/>*/}
                <img src="/img/logo.svg" alt="Logo ChineseTutor.org" />
              </a>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="topbar">
              <div className="topbar-col">
                <a href="mailto:info@chinesetutor.org">
                  <i className="fa fa-envelope"></i>info@chinesetutor.org
                </a>
              </div>
              <div className="topbar-col">
                <ProfileNavBar location={location} />
              </div>
            </div>
            <Navbar
              menuLinks={menuLinks}
              currPage={currPage}
              location={location}
            />
          </div>
        </div>
        {currPage === "home" ? <MainHeader /> : ""}
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  currPage: PropTypes.string.isRequired,
  menuLinks: PropTypes.array.isRequired,
}

Header.defaultProps = {
  siteTitle: ``,
  currPage: "home",
}

export default Header
