import React from "react"
import { Link } from "gatsby"
import useAuth from "../util/useStrapiAuth"
import { passRedirectThroughAuth } from "../util/authRedirect"
import { navigate } from "gatsby"
import env from "exenv"

export default function ProfileNavBar({ location }) {
  /*optionally change the redirect.
  If there was already a redirect which is not part of the registration/login system, 
  then keep using this redirect.
  If there was no redirect yet, then just pass the current location 
  */
  const auth = useAuth()
  const logout = () => {
    auth.logout()
    //if current page is within "/dashboard" path --> navigate to '/'
    //if current page is not within  "/dashboard" path --> don't navigate (stay on page)
    if (location.pathname.startsWith("/dashboard")) {
      navigate(location.origin, { replace: true, state: { redirect: null } })
    } else {
      if (env.canUseDOM) {
        window.location.reload()
      }
    }
  }

  const loc = location?.pathname?.replace(/\/$/, "")
  let links = [],
    linkClass = null
  if (env.canUseDOM || location.state?.loggedIn) {
    //client-side (browser) rendering
    if (auth.isAuthenticated || location.state?.loggedIn) {
      //If logged in, show "dashboard" and "log out"
      linkClass = loc.startsWith("/dashboard") ? "currentLink" : null
      links.push(
        <Link key="1" className={linkClass} to="/dashboard">
          Dashboard
        </Link>
      )
      links.push(` `)
      links.push(
        <button key="2" className={linkClass} onClick={logout}>
          Log out
        </button>
      )
    } else {
      //If not logged in, show "register" and "login"
      linkClass = loc === "/register" ? "currentLink" : null
      links.push(
        <Link
          key="1"
          className={linkClass}
          to="/register"
          state={{ redirect: passRedirectThroughAuth(location, "/register") }}
        >
          Register
        </Link>
      )
      links.push(` `)
      linkClass = loc === "/login" ? "currentLink" : null
      links.push(
        <Link
          key="2"
          className={linkClass}
          to="/login"
          state={{ redirect: passRedirectThroughAuth(location, "/login") }}
        >
          Log in
        </Link>
      )
    }
  } else {
    //server-side rendering (for search engine crawlers)
    //show "register" and "log in"
    links.push(
      <Link key="1" to="/register">
        Register
      </Link>
    )
    links.push(` `)
    links.push(
      <Link key="2" to="/login" state={{ redirect: location?.pathname }}>
        Log in
      </Link>
    )
  }

  return (
    <div
      style={{
        display: "flex",
        flex: "1",
        justifyContent: "space-between",
      }}
    >
      <nav>{links}</nav>
    </div>
  )
}
